import React from 'react'


class ShitTag extends React.Component {

    render() {

        return (
            <div className="ShitTag">
                Shitstorm <span className="version">beta</span> 
            </div>
        )
    }
}


export default ShitTag
